<router>
{
    name: 'Dashboard'
}
</router>
<template lang="pug">
v-app
    v-navigation-drawer(permanent, dark, color="brand", app)
        v-list.pt-1(dark)
            v-list-item.px-2(@click="gotoPage('/')")
                v-list-item-icon
                    v-img.ml-2(
                        src="/DorsettControls_Symbol_White.png",
                        max-height="24",
                        max-width="24"
                    )
                v-list-item-title
                    v-list-item-title.title Dorsett Controls
        v-divider.mb-2
        v-list.py-0(
            dark,
            v-for="(item, idx) in items",
            :key="item.title + idx",
            v-if="$accessible(item)"
        )
            v-list-group(no-action, :value="true")
                template(v-slot:activator)
                    v-list-item-content
                        v-list-item-title {{ item.title }}

                v-list-item(
                    v-for="(child, idx) in item.children",
                    :key="child.title + idx",
                    @click="gotoPage(child, item)",
                    v-if="$accessible(child)"
                )
                    v-list-item-title(v-text="child.title")
        template(v-slot:append)
            v-list(nav, dark)
                v-list-item(@click="logout")
                    v-list-item-icon
                        v-icon logout
                    v-list-item-content
                        v-list-item-title Logout

    v-main.white
        v-app-bar.topBar.white--text(flat, color="brand", light, app)
            //- v-img.mr-4(src='/DorsettControls_Symbol_White.png', max-height='24', max-width='24')
            v-toolbar-title {{ pageTitle }}
            v-spacer
            //- v-text-field.mr-2.search(
            //-     color="brand",
            //-     append-icon="search",
            //-     dense,
            //-     hide-details,
            //-     outlined
            //- )
            //- v-btn(icon)
            //-     v-icon notifications
            //- v-btn(icon)
            //-     v-icon person


        router-view
        v-container.landingPage(v-if='showLandingPage')
            v-row
                v-spacer
                v-col(cols='6')
                    v-img(src='/DCLogo.jpg')
                v-spacer
            v-row
                v-spacer
                v-col.text-center(cols='6')
                    p.black--text Welcome! Use the navigation bar on the left side to access different content.
                v-spacer

    //- v-expand-transition
        v-overlay(v-if='loading', z-index='999', opacity='1')
            #dots5
                span(v-for='i in new Array(5)')
            .text-center.text-h5.mt-8 Loading

    v-snackbar(
        v-model="snackbar",
        bottom,
        :timeout="snackbarTimeout",
        :color="snackbarColor"
    ) {{ snackbarText }}
        template(v-slot:action)
            v-btn(dark, text, @click="snackbar = false") Close
</template>
<script>
import VueGridLayout from "vue-grid-layout";
import moment from "moment";
import dti from "dti";
import { nanoid } from "nanoid";
dti.makeuuid = (len = 10) => {
    return nanoid(len);
};

export default {
    components: {
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem,
    },
    data () {
        return {
            loading: true,
            snackbar: false,
            snackbarText: "",
            snackbarColor: "red",
            snackbarTimeout: 3000,
            moment,

            breadcrumb: null,
            test: "Test",
            items: [
                {
                    title: "DCCS",
                    icon: "edit",
                    role: "admin",
                    children: [
                        {
                            link: "/dccs/permissions",
                            title: "Permissions",
                            role: "admin",
                        },
                        {
                            link: "/dccs/serversettings",
                            title: "Server Settings",
                            role: "admin",
                        },
                    ],
                },
                {
                    title: "Projects",
                    icon: "Calculate",
                    role: "projects",
                    children: [
                        {
                            link: "/projects/jonasTools",
                            title: "Jonas Tools",
                            role: "projectsjonas",
                        },
                        {
                            link: '/sat/infoscanexport',
                            title: 'SAT Project Export',
                            role: 'infoscanexport'
                        }
                    ],
                },
                {
                    title: "Sales Tool",
                    icon: "attach_money",
                    role: "sat",
                    children: [
                        {
                            link: "/sat/download",
                            title: "Download",
                            role: "satdownload",
                        },
                        {
                            link: "/sat/versionmanager",
                            title: "Version Manager",
                            role: "satversions",
                        },
                        {
                            link: "/sat/bonds",
                            title: "Bonds",
                            role: "satbonds",
                        },
                        {
                            link: "/sat/systemtags",
                            title: "System Tags",
                            role: "satsystemtags",
                        },
                        {
                            link: "/sat/controllers",
                            title: "Controllers",
                            role: "satcontrollers",
                        },
                        {
                            link: "/sat/labor",
                            title: "Labor Rates",
                            role: "satlabor",
                        },
                        {
                            link: "/sat/satlabortypes",
                            title: "Labor Types",
                            role: "satlabortypes",
                        },
                        {
                            link: "/sat/markup",
                            title: "Markup",
                            role: "satmarkup",
                        },
                        {
                            link: "/sat/panels",
                            title: "Panels",
                            role: "satpanels",
                        },
                        {
                            link: "/sat/partgroups",
                            title: "Part Groups",
                            role: "satpartgroups",
                        },
                        {
                            link: "/sat/users",
                            title: "Users",
                            role: "satusers",
                        },
                        {
                            link: "/sat/escalationrules",
                            title: "Escalation Rules",
                            role: "satescalationrules",
                        },
                        {
                            link: "/sat/licensing",
                            title: "Licensing",
                            role: "licensing",
                        },
                        {
                            link: "/sat/parts",
                            title: "Parts",
                            role: "satparts",
                        },
                    ],
                },
                {
                    title: "Customers",
                    icon: "edit",
                    role: "devops",
                    children: [
                        {
                            link: "/devops/help",
                            title: "Help",
                            role: "devopshelp",
                        },
                        {
                            link: '/devops/downloadClient',
                            title: 'Download Client',
                            role: 'devopsdownloadclient'
                        },
                        {
                            link: "/devops/update",
                            title: "Update",
                            role: "devopsupdate",
                        },
                        {
                            link: "/devops/versions",
                            title: "Versions",
                            role: "devopsversions",
                        },
                        {
                            link: "/devops/customers",
                            title: "Customers",
                            role: "devopsversions",
                        },
                        {
                            link: '/devops/users',
                            title: 'Users',
                            role: 'devopsusers'
                        },
                        {
                            link: '/devops/database',
                            title: 'DC Users',
                            role: 'devopsdatabase'
                        },
                        {
                            link: '/devops/communications',
                            title: 'Communications',
                            role: 'devopscomms'
                        },
                        {
                            link: '/devops/training',
                            title: 'Training',
                            role: 'customertraining'
                        },
                        {
                            link: '/devops/dependencies',
                            title: 'Dependencies',
                            role: 'devopsdependencies'
                        }
                    ],
                },
                {
                    title: "Analytics",
                    icon: "edit",
                    role: "analytics",
                    children: [
                        {
                            link: "/analytics/tasks",
                            title: "Tasks",
                            role: "analytics-tasks",
                        },
                        {
                            link: "/analytics/taskQueue",
                            title: "Task Queue",
                            role: "analytics-queue",
                        },
                        {
                            link: "/analytics/uom",
                            title: "Units of Measure",
                            role: "analytics-uom",
                        },
                    ]
                }
            ],
            mini: true,
        };
    },
    methods: {
        handleNavbarHover (mini) {
            console.log("handleNavbarHover", mini);
            this.mini = mini;
        },
        gotoPage (item, parent) {
            if (!item) {
                return;
            }
            this.$router.push({
                path: typeof item === "object" ? item.link : item,
            });
            if (typeof item === "object" && parent.title && item.title) {
                this.breadcrumb = [parent.title, item.title].join(" > ");
            } else {
                this.breadcrumb = null;
            }
        },
        logout () {
            this.$store.dispatch("logout");
        },
    },
    computed: {
        errorMessage () {
            return this.$store.getters.errorMessage;
        },
        pageTitle () {
            return this.breadcrumb || this.$route.name;
        },
        loggingIn () {
            return this.$store.getters.loggingIn;
        },
        showLandingPage () {
            return this.$route.path === '/';
        }
    },
    watch: {
        loggingIn (val) {
            if (val === false) {
                this.loading = false;
            }
        },
        loading (val) {
            if (!val) {
                this.$store.dispatch("startup");
            }
            this.$store.commit("setLoading", val);
        },
        errorMessage (val) {
            if (val && val !== "") {
                this.snackbarColor = "red";
                this.snackbarText = val;
                this.snackbar = true;
                setTimeout(() => {
                    this.snackbarText = "";
                }, this.snackbarTimeout + 1000);
            }
        },
    },
    mounted () {
        window.ivm = this;

        if (!this.loggingIn) {
            this.loading = false;
        }
    },
    created () {
        this.$store.dispatch('connectSocket');
    }
};
</script>

<style scoped lang="scss">
nav {
    img {
        width: 24px;
        height: 24px;
    }

    a {
        text-decoration: none;
        // color: initial;
    }
}

.topBar {
    .search {
        max-width: 300px;
    }
}

/**=== dots5 ===*/
#dots5 {
    display: flex;
    position: absolute;
    justify-content: space-between;
    top: 50%;
    left: 50%;
    height: 50px;
    width: 75px;
    transform: translate(-50%, -50%);
}

#dots5 span {
    // position: absolute;
    width: 10px;
    height: 10px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    -webkit-animation: dots5 1s infinite ease-in-out;
    animation: dots5 1s infinite ease-in-out;
}

@for $c from 1 through 10 {
    #dots5 span:nth-child(#{$c}) {
        animation-delay: -1s + (0.1 * ($c));
    }
}

$dcLight: #009eda;
$dcDark: #00355c;

@keyframes dots5 {
    0% {
        transform: translateY(0px);
        background: white;
    }

    33% {
        transform: translateY(10px);
        background: $dcLight;
    }

    66% {
        transform: translateY(10px);
        background: $dcDark;
    }

    100% {
        transform: translateY(0px);
        background: white;
    }
}

/** END of dots5 */
</style>
<style lang="scss">
nav {
    .v-list-group__header {
        margin-bottom: 0 !important;
    }
}
</style>
